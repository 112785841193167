import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoVernal from "../../Assets/Images/logo-vernal-white.svg";
import logoMenu from "../../Assets/Images/logo-vernal-black.png";
import closeMenu from "../../Assets/Images/menu-close.svg";
import Ig from "../../Assets/Images/media-ig-white.svg";
import Be from "../../Assets/Images/media-be-white.svg";
import In from "../../Assets/Images/media-in-white.svg";
import Button from "../Button/Button";

export default function NavBar({ langEn, setLangEn }) {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [zIndex, setZIndex] = useState(false);
  const [menuButton, setMenuButton] = useState(false);
  const [menuIsActive, setMenuIsActive] = useState(false);
  const toggleMenu = () => {
    setMenu(!menu);
    setZIndex(true);
    setMenuButton(!menuButton);
    setMenuIsActive(!menuIsActive);
  };
  const toggleLanguage = () => {
    if (langEn) {
      setLangEn(false);
      navigate("/es");
    } else {
      setLangEn(true);
      navigate("/en");
    }
  };

  return (
    <header className="NavBar" id="header">
      <div className="NavBarContainer">
        {/* HEADER MENU BUTTON */}
        <div
          onClick={toggleMenu}
          className={`NavBarContainer__MenuButton Header-Animation ${
            menuButton ? "MenuButtonFixed" : ""
          }`}
        >
          <div
            className={`Menu-Button-Span Menu-Button-1 ${
              menuIsActive ? "menuButtonIsActive" : "menuButtonIsInactive1"
            }`}
          ></div>
          <div
            className={`Menu-Button-Span Menu-Button-2 ${
              menuIsActive ? "menuButtonIsActive" : "menuButtonIsInactive2"
            }`}
          ></div>
          <div
            className={`Menu-Button-Span Menu-Button-3 ${
              menuIsActive ? "menuButtonIsActive" : "menuButtonIsInactive3"
            }`}
          ></div>
          <div
            className={`Menu-Button-Span Menu-Button-4 ${
              menuIsActive ? "menuButtonIsActive" : "menuButtonIsInactive4"
            }`}
          ></div>
          <div
            className={`Menu-Button-Close  ${
              menuIsActive ? "menuCloseIsActive" : "menuCloseIsInactive"
            }`}
          >
            <img alt="Close" className="CloseButton" src={closeMenu}></img>
          </div>
        </div>
        {/* HEADER LOGO */}
        <Link
          to={langEn ? "/en" : "/es"}
          className="NavBarContainer__Logo Header-Animation"
        >
          <img
            className="NavBarContainer__Logo--Image"
            src={logoVernal}
            alt="logo"
          ></img>
        </Link>
        {/* HEADER LANGUAGE SELECT */}
        <div
          onClick={toggleLanguage}
          className="NavBarContainer__LanguageButton Header-Animation"
          id="language-button-desktop"
        >
          <p className="NavBarContainer__LanguageButton--Selected">
            {langEn ? "EN" : "ES"}
          </p>
        </div>
      </div>
      {/* MENU */}
      <div
        style={{ zIndex }}
        id={`${zIndex ? "MenuContainer" : "MenuContainerZIndex"}`}
        className={`Menu ${menu ? "MenuIsActive" : "Menu-Animation"}`}
      >
        <div className="MenuContainer">
          <div className="MenuContainer__Top">
            {/* CLOSE BUTTON */}

            {/* LOGO */}
            <Link
              to={langEn ? "/en" : "/es"}
              className="NavBarContainer__Logo Header-Animation"
            >
              <img
                className={`MenuContainer__Top--Logo ${
                  menu ? "" : "Navbar-Logo-AnimationOut"
                }`}
                onClick={toggleMenu}
                alt="Logo"
                src={logoMenu}
              ></img>
            </Link>

            {/* LANGUAGE */}
            <div
              onClick={toggleLanguage}
              className={`MenuContainer__Top--LanguageButton ${
                menu
                  ? "Navbar-Language-AnimationIn"
                  : "Navbar-Language-AnimationOut"
              }`}
            >
              {langEn ? "EN" : "ES"}
            </div>
          </div>
          {/* MENU OPTIONS */}
          <div className="MenuContainer__Links">
            <Link
              to={langEn ? "en/about-us" : "/es/nosotros"}
              onClick={toggleMenu}
              className={`MenuContainer__Links--Options  ${
                menu ? "Navbar-Links-AnimationIn" : "Navbar-Links-AnimationOut"
              }`}
            >
              {langEn ? "About us" : "Nosotros"}
            </Link>
            <Link
              to={langEn ? "/en/projects" : "/es/proyectos"}
              onClick={toggleMenu}
              className={`MenuContainer__Links--Options   ${
                menu ? "Navbar-Links-AnimationIn" : "Navbar-Links-AnimationOut"
              }`}
            >
              {langEn ? "Projects" : "Proyectos"}
            </Link>
            <Link
              to={langEn ? "/en/services" : "/es/servicios"}
              onClick={toggleMenu}
              className={`MenuContainer__Links--Options   ${
                menu ? "Navbar-Links-AnimationIn" : "Navbar-Links-AnimationOut"
              }`}
            >
              {langEn ? "Servicies" : "Servicios"}
            </Link>
            <Link
              to={langEn ? "/en/approach" : "/es/approach"}
              onClick={toggleMenu}
              className={`MenuContainer__Links--Options   ${
                menu ? "Navbar-Links-AnimationIn" : "Navbar-Links-AnimationOut"
              }`}
            >
              Approach
            </Link>
          </div>
          <div className="NavBar-Line-Container">
            <div
              className={`NavBar-Line ${
                menu
                  ? "Navbar-MediaLine-AnimationIn"
                  : "Navbar-MediaLine-AnimationOut"
              }`}
            ></div>
          </div>
          {/* MENU CONTACT */}
          <div className="MenuContainer__Contact">
            <Link
              to={langEn ? "/en/contact" : "/es/contacto"}
              onClick={toggleMenu}
              className={`MenuContainer__Contact--Text  ${
                menu
                  ? "Navbar-ButtonText-AnimationIn"
                  : "Navbar-ButtonText-AnimationOut"
              }`}
            >
              <Button
                text={langEn ? "GET IN TOUCH" : "ESCRIBINOS"}
                position={"center"}
              />
            </Link>
          </div>
          {/* SOCIAL MEDIA */}
          <div className="MenuContainer__Media">
            <div
              className={`MenuContainer__Media--Line ${
                menu
                  ? "Navbar-MediaLine-AnimationIn"
                  : "Navbar-MediaLine-AnimationOut"
              }`}
            ></div>
            <a
              className="MenuContainer__Media--Link  Media-Container-Animation"
              href="https://www.instagram.com/vernalstudio/"
              rel="noreferrer"
              target="_blank"
            >
              {" "}
              <span className="Menu-Media-Animation Media-Animation"></span>
              <img
                alt="Instagram"
                src={Ig}
                className={`Media-Ig Menu-Ig Social-Media-Image ${
                  menu
                    ? "Navbar-MediaLinks-AnimationIn"
                    : "Navbar-MediaLinks-AnimationOut"
                }`}
              ></img>
            </a>
            <a
              className="MenuContainer__Media--Link  Media-Container-Animation"
              href="http://www.behance.net/vernalstudio"
              rel="noreferrer"
              target="_blank"
            >
              {" "}
              <span className="Menu-Media-Animation Media-Animation"></span>
              <img
                alt="Behance"
                src={Be}
                className={`Media-Be Menu-Be Social-Media-Image ${
                  menu
                    ? "Navbar-MediaLinks-AnimationIn"
                    : "Navbar-MediaLinks-AnimationOut"
                }`}
              ></img>
            </a>
            <a
              className="MenuContainer__Media--Link  Media-Container-Animation"
              href="https://www.linkedin.com/company/vernal-studio/"
              rel="noreferrer"
              target="_blank"
            >
              {" "}
              <span className="Menu-Media-Animation Media-Animation"></span>
              <img
                alt="Linkedin"
                src={In}
                className={`Media-In Menu-In Social-Media-Image ${
                  menu
                    ? "Navbar-MediaLinks-AnimationIn"
                    : "Navbar-MediaLinks-AnimationOut"
                }`}
              ></img>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
