import "./scss/Styles.scss";
import {
  HashRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import HomePage from "./Pages/HomePage";
import Footer from "./Components/Footer/Footer";
import ProjectTemplate from "./Pages/ProjectTemplate";
import ProjectsPage from "./Pages/ProjectsPage";
import ScrollToTop from "./Utils/ScrollToTop";
import NavBar from "./Components/NavBar/NavBar";
import { useEffect, useState } from "react";

function App() {
  const [langEn, setLangEn] = useState(true);

  return (
    <div className="App">
      <HashRouter>
        <Content langEn={langEn} setLangEn={setLangEn} />
      </HashRouter>
    </div>
  );
}

function Content({ langEn, setLangEn }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath === "/") {
      navigate("/en", { replace: true });
    } else if (currentPath.startsWith("/en")) {
      setLangEn(true);
    } else if (currentPath.startsWith("/es")) {
      setLangEn(false);
    }
  }, [location.pathname, navigate]);

  return (
    <>
      <ScrollToTop />
      <NavBar langEn={langEn} setLangEn={setLangEn} />
      <Routes>
        <Route path="/en" element={<HomePage langEn={langEn} />} />
        <Route path="/es" element={<HomePage langEn={langEn} />} />
        <Route path="/en/about-us" element={<HomePage langEn={langEn} />} />
        <Route path="/es/nosotros" element={<HomePage langEn={langEn} />} />
        <Route path="/en/services" element={<HomePage langEn={langEn} />} />
        <Route path="/es/servicios" element={<HomePage langEn={langEn} />} />
        <Route path="/en/contact" element={<HomePage langEn={langEn} />} />
        <Route path="/es/contacto" element={<HomePage langEn={langEn} />} />
        <Route path="/en/approach" element={<HomePage langEn={langEn} />} />
        <Route path="/es/approach" element={<HomePage langEn={langEn} />} />
        <Route path="/en/projects" element={<ProjectsPage langEn={langEn} />} />
        <Route
          path="/es/proyectos"
          element={<ProjectsPage langEn={langEn} />}
        />
        <Route
          path="/en/projects/:route"
          element={<ProjectTemplate langEn={langEn} />}
        />
        <Route
          path="/es/proyectos/:route"
          element={<ProjectTemplate langEn={langEn} />}
        />
      </Routes>
      <Footer langEn={langEn} />
    </>
  );
}

export default App;
